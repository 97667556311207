<div class="account_items">
  <div *ngIf="hasToken; else noTokenyet" class="account_item no_border">
    <button *ngIf="tanValidationEnabled" (click)="onFetchToken()"><h3>Neuen Code bestellen</h3></button>
  </div>
  <ng-template #noTokenyet>
    <div class="account_item no_border">
      <button *ngIf="tanValidationEnabled" (click)="onFetchToken()"><h1>JETZT BIETEN</h1></button>
    </div>
  </ng-template>
  <div class="account_item no_border" *ngIf="!tanValidationEnabled || hasToken">
    <div class="formrow" *ngIf="hasToken">
          Du hast eben einen Code per SMS auf dein Mobile geschickt bekommen.<br>
    </div>
    <form [formGroup]="form" *ngIf="showForm()" (ngSubmit)="onSubmit()">
      <div class="formrow" *ngIf="tanValidationEnabled">
        <label for="tan">SMS Code eingeben:</label>
        <input id="tan" type="text" formControlName="tan" class="form-control" required
          [ngClass]="{ 'is-invalid': submitted && f['tan'].errors }" />
        <div *ngIf="submitted && f['tan'].errors" class="invalid-feedback">
          <div *ngIf="f['tan'].errors['required']">Der SMS Code muss eingegeben werden</div>
        </div>
      </div>
      <div class="formrow">
        <label for="amount">Dein Gebot in CHF eingeben:</label>
        <input id="amount" type="number" [min]="minimumBidAmount || 0" max="999999999" step="1" formControlName="amount" class="form-control" required
          [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }" />
        <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
          <div *ngIf="f['amount'].errors['required']">Ein Gebot muss eingegeben werden</div>
          <div *ngIf="exhibit.minimum_amount && (f['amount'].errors['min']) && ((minimumBidAmount || 0) <= exhibit.minimum_amount)">Dein Gebot muss mindestens dem Startgebot entsprechen</div>
          <div *ngIf="exhibit.minimum_amount && f['amount'].errors['min'] && ((minimumBidAmount || 0) > exhibit.minimum_amount)">Dein Gebot muss das aktuell höchste Gebot um mind. CHF {{ exhibit.bidding_step }} übertreffen</div>
          <div *ngIf="f['amount'].errors['max']">Das Gebot darf nicht in Milliardenhöhe sein</div>
          <div *ngIf="f['amount'].errors['pattern']">Das Gebot darf keine Rappen beinhalten</div>
        </div>
      </div>
      <div class="formrow">
        <button>
          <h1>GEBOT ABGEBEN</h1>
        </button>
      </div>
    </form>
  </div>
</div>