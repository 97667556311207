import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@app/_services';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  form!: FormGroup;
  code: string | null = null;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.queryParams['code'];
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid || !this.code) {
      return;
    }

    this.accountService.resetPassword(this.form.controls['password'].value, this.code)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Passwort zurückgesetzt!');
          this.router.navigateByUrl('/');
        },
        error: (error) => {
          this.toastr.error(error.message);
        }
      });
  }
}
