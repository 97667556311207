import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Bid",
      "Category",
      "Contact",
      "Exhibit",
      "Exhibition",
      "Gtc",
      "I18NLocale",
      "Impressum",
      "Laudatio",
      "Policy",
      "Regulation",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VirtualTour"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type Bid = {
  __typename?: 'Bid';
  amount: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  exhibit?: Maybe<ExhibitEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users_permissions_user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type BidEntity = {
  __typename?: 'BidEntity';
  attributes?: Maybe<Bid>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type BidEntityResponse = {
  __typename?: 'BidEntityResponse';
  data?: Maybe<BidEntity>;
};

export type BidEntityResponseCollection = {
  __typename?: 'BidEntityResponseCollection';
  data: Array<BidEntity>;
  meta: ResponseCollectionMeta;
};

export type BidFiltersInput = {
  amount?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<BidFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  exhibit?: InputMaybe<ExhibitFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<BidFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BidFiltersInput>>>;
  otp?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users_permissions_user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type BidInput = {
  amount?: InputMaybe<Scalars['Int']['input']>;
  exhibit?: InputMaybe<Scalars['ID']['input']>;
  otp?: InputMaybe<Scalars['String']['input']>;
  users_permissions_user?: InputMaybe<Scalars['ID']['input']>;
};

export type BidRelationResponseCollection = {
  __typename?: 'BidRelationResponseCollection';
  data: Array<BidEntity>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Category = {
  __typename?: 'Category';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  attributes?: Maybe<Category>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse';
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Contact = {
  __typename?: 'Contact';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactEntity = {
  __typename?: 'ContactEntity';
  attributes?: Maybe<Contact>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ContactEntityResponse = {
  __typename?: 'ContactEntityResponse';
  data?: Maybe<ContactEntity>;
};

export type ContactInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  titel?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTwilioVerifyVerificationResponse = {
  __typename?: 'CreateTwilioVerifyVerificationResponse';
  status: Scalars['String']['output'];
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Exhibit = {
  __typename?: 'Exhibit';
  auctionEnabled: Scalars['Boolean']['output'];
  bidding_step: Scalars['Int']['output'];
  bids?: Maybe<BidRelationResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image: UploadFileEntityResponse;
  minimum_amount?: Maybe<Scalars['Int']['output']>;
  moretext?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  sold: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type ExhibitBidsArgs = {
  filters?: InputMaybe<BidFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ExhibitEntity = {
  __typename?: 'ExhibitEntity';
  attributes?: Maybe<Exhibit>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ExhibitEntityResponse = {
  __typename?: 'ExhibitEntityResponse';
  data?: Maybe<ExhibitEntity>;
};

export type ExhibitEntityResponseCollection = {
  __typename?: 'ExhibitEntityResponseCollection';
  data: Array<ExhibitEntity>;
  meta: ResponseCollectionMeta;
};

export type ExhibitFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ExhibitFiltersInput>>>;
  auctionEnabled?: InputMaybe<BooleanFilterInput>;
  biddingLocked?: InputMaybe<BooleanFilterInput>;
  bidding_step?: InputMaybe<IntFilterInput>;
  bids?: InputMaybe<BidFiltersInput>;
  category?: InputMaybe<CategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  minimum_amount?: InputMaybe<IntFilterInput>;
  moretext?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ExhibitFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ExhibitFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  sold?: InputMaybe<BooleanFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ExhibitInput = {
  auctionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  biddingLocked?: InputMaybe<Scalars['Boolean']['input']>;
  bidding_step?: InputMaybe<Scalars['Int']['input']>;
  bids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  category?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  minimum_amount?: InputMaybe<Scalars['Int']['input']>;
  moretext?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sold?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Exhibition = {
  __typename?: 'Exhibition';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ExhibitionEntity = {
  __typename?: 'ExhibitionEntity';
  attributes?: Maybe<Exhibition>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ExhibitionEntityResponse = {
  __typename?: 'ExhibitionEntityResponse';
  data?: Maybe<ExhibitionEntity>;
};

export type ExhibitionInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  titel?: InputMaybe<Scalars['String']['input']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type GenericMorph = Bid | Category | Contact | Exhibit | Exhibition | Gtc | I18NLocale | Impressum | Laudatio | Policy | Regulation | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | VirtualTour;

export type Gtc = {
  __typename?: 'Gtc';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GtcEntity = {
  __typename?: 'GtcEntity';
  attributes?: Maybe<Gtc>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GtcEntityResponse = {
  __typename?: 'GtcEntityResponse';
  data?: Maybe<GtcEntity>;
};

export type GtcInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  titel?: InputMaybe<Scalars['String']['input']>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type Impressum = {
  __typename?: 'Impressum';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ImpressumEntity = {
  __typename?: 'ImpressumEntity';
  attributes?: Maybe<Impressum>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ImpressumEntityResponse = {
  __typename?: 'ImpressumEntityResponse';
  data?: Maybe<ImpressumEntity>;
};

export type ImpressumInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  titel?: InputMaybe<Scalars['String']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type Laudatio = {
  __typename?: 'Laudatio';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LaudatioEntity = {
  __typename?: 'LaudatioEntity';
  attributes?: Maybe<Laudatio>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LaudatioEntityResponse = {
  __typename?: 'LaudatioEntityResponse';
  data?: Maybe<LaudatioEntity>;
};

export type LaudatioInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createBid?: Maybe<BidEntityResponse>;
  createBidTransactional?: Maybe<BidEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  createExhibit?: Maybe<ExhibitEntityResponse>;
  createTwilioVerifyVerification?: Maybe<CreateTwilioVerifyVerificationResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteBid?: Maybe<BidEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  deleteContact?: Maybe<ContactEntityResponse>;
  deleteExhibit?: Maybe<ExhibitEntityResponse>;
  deleteExhibition?: Maybe<ExhibitionEntityResponse>;
  deleteGtc?: Maybe<GtcEntityResponse>;
  deleteImpressum?: Maybe<ImpressumEntityResponse>;
  deleteLaudatio?: Maybe<LaudatioEntityResponse>;
  deletePolicy?: Maybe<PolicyEntityResponse>;
  deleteRegulation?: Maybe<RegulationEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteVirtualTour?: Maybe<VirtualTourEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateBid?: Maybe<BidEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  updateContact?: Maybe<ContactEntityResponse>;
  updateExhibit?: Maybe<ExhibitEntityResponse>;
  updateExhibition?: Maybe<ExhibitionEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGtc?: Maybe<GtcEntityResponse>;
  updateImpressum?: Maybe<ImpressumEntityResponse>;
  updateLaudatio?: Maybe<LaudatioEntityResponse>;
  updateMe?: Maybe<UsersPermissionsMe>;
  updatePolicy?: Maybe<PolicyEntityResponse>;
  updateRegulation?: Maybe<RegulationEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateVirtualTour?: Maybe<VirtualTourEntityResponse>;
  upload: UploadFileEntityResponse;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


export type MutationCreateBidArgs = {
  data: BidInput;
};


export type MutationCreateBidTransactionalArgs = {
  data: BidInput;
};


export type MutationCreateCategoryArgs = {
  data: CategoryInput;
};


export type MutationCreateExhibitArgs = {
  data: ExhibitInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteBidArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteExhibitArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


export type MutationUpdateBidArgs = {
  data: BidInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateCategoryArgs = {
  data: CategoryInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateContactArgs = {
  data: ContactInput;
};


export type MutationUpdateExhibitArgs = {
  data: ExhibitInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateExhibitionArgs = {
  data: ExhibitionInput;
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateGtcArgs = {
  data: GtcInput;
};


export type MutationUpdateImpressumArgs = {
  data: ImpressumInput;
};


export type MutationUpdateLaudatioArgs = {
  data: LaudatioInput;
};


export type MutationUpdateMeArgs = {
  data: UpdateMeInput;
};


export type MutationUpdatePolicyArgs = {
  data: PolicyInput;
};


export type MutationUpdateRegulationArgs = {
  data: RegulationInput;
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateVirtualTourArgs = {
  data: VirtualTourInput;
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type Policy = {
  __typename?: 'Policy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PolicyEntity = {
  __typename?: 'PolicyEntity';
  attributes?: Maybe<Policy>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PolicyEntityResponse = {
  __typename?: 'PolicyEntityResponse';
  data?: Maybe<PolicyEntity>;
};

export type PolicyInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  titel?: InputMaybe<Scalars['String']['input']>;
};

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export type Query = {
  __typename?: 'Query';
  bid?: Maybe<BidEntityResponse>;
  bids?: Maybe<BidEntityResponseCollection>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  contact?: Maybe<ContactEntityResponse>;
  exhibit?: Maybe<ExhibitEntityResponse>;
  exhibition?: Maybe<ExhibitionEntityResponse>;
  exhibits?: Maybe<ExhibitEntityResponseCollection>;
  gtc?: Maybe<GtcEntityResponse>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  impressum?: Maybe<ImpressumEntityResponse>;
  laudatio?: Maybe<LaudatioEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  policy?: Maybe<PolicyEntityResponse>;
  regulation?: Maybe<RegulationEntityResponse>;
  twilioVerifySettings?: Maybe<TwilioVerifySettingsResponse>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userExhibits?: Maybe<ExhibitEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  virtualTour?: Maybe<VirtualTourEntityResponse>;
};


export type QueryBidArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryBidsArgs = {
  filters?: InputMaybe<BidFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryExhibitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  slug: Scalars['String']['input'];
};


export type QueryExhibitsArgs = {
  filters?: InputMaybe<ExhibitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Regulation = {
  __typename?: 'Regulation';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  titel?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RegulationEntity = {
  __typename?: 'RegulationEntity';
  attributes?: Maybe<Regulation>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type RegulationEntityResponse = {
  __typename?: 'RegulationEntityResponse';
  data?: Maybe<RegulationEntity>;
};

export type RegulationInput = {
  text?: InputMaybe<Scalars['String']['input']>;
  titel?: InputMaybe<Scalars['String']['input']>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioVerifySettingsResponse = {
  __typename?: 'TwilioVerifySettingsResponse';
  enabled: Scalars['Boolean']['output'];
};

export type UpdateMeInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  address?: Maybe<Scalars['String']['output']>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gtcAccepted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gtcAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  address: Scalars['String']['output'];
  bids?: Maybe<BidRelationResponseCollection>;
  blocked?: Maybe<Scalars['Boolean']['output']>;
  city: Scalars['String']['output'];
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gtcAccepted: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  mobile: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};


export type UsersPermissionsUserBidsArgs = {
  filters?: InputMaybe<BidFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  address?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  bids?: InputMaybe<BidFiltersInput>;
  blocked?: InputMaybe<BooleanFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  firstName?: InputMaybe<StringFilterInput>;
  gtcAccepted?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  mobile?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
  zip?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  bids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gtcAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type VirtualTour = {
  __typename?: 'VirtualTour';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type VirtualTourEntity = {
  __typename?: 'VirtualTourEntity';
  attributes?: Maybe<VirtualTour>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type VirtualTourEntityResponse = {
  __typename?: 'VirtualTourEntityResponse';
  data?: Maybe<VirtualTourEntity>;
};

export type VirtualTourInput = {
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type TwilioVerifySettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TwilioVerifySettingsQuery = { __typename?: 'Query', twilioVerifySettings?: { __typename?: 'TwilioVerifySettingsResponse', enabled: boolean } | null };

export type CreateBidTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateBidTokenMutation = { __typename?: 'Mutation', createTwilioVerifyVerification?: { __typename?: 'CreateTwilioVerifyVerificationResponse', status: string } | null };

export type BidAttributesFragment = { __typename?: 'Bid', amount: number, createdAt?: any | null, updatedAt?: any | null, users_permissions_user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null };

export type BidEntityAttributesFragment = { __typename?: 'BidEntity', id?: string | null, attributes?: { __typename?: 'Bid', amount: number, createdAt?: any | null, updatedAt?: any | null, users_permissions_user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null } | null };

export type ExhibitBidsQueryVariables = Exact<{
  filterInput: BidFiltersInput;
  pageSize: Scalars['Int']['input'];
}>;


export type ExhibitBidsQuery = { __typename?: 'Query', bids?: { __typename?: 'BidEntityResponseCollection', data: Array<{ __typename?: 'BidEntity', id?: string | null, attributes?: { __typename?: 'Bid', amount: number, createdAt?: any | null, updatedAt?: any | null, users_permissions_user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, total: number, pageSize: number, pageCount: number } } } | null };

export type CreateBidTransactionalMutationVariables = Exact<{
  input: BidInput;
}>;


export type CreateBidTransactionalMutation = { __typename?: 'Mutation', createBidTransactional?: { __typename?: 'BidEntityResponse', data?: { __typename?: 'BidEntity', id?: string | null, attributes?: { __typename?: 'Bid', amount: number, createdAt?: any | null, updatedAt?: any | null, users_permissions_user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null } | null } | null } | null };

export type CategoryAttributesFragment = { __typename?: 'Category', name: string, slug: string };

export type CategoryEntityAttributesFragment = { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryEntityResponseCollection', data: Array<{ __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null }> } | null };

export type ContactQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactQuery = { __typename?: 'Query', contact?: { __typename?: 'ContactEntityResponse', data?: { __typename?: 'ContactEntity', attributes?: { __typename?: 'Contact', text?: string | null, titel?: string | null } | null } | null } | null };

export type ExhibitionQueryVariables = Exact<{ [key: string]: never; }>;


export type ExhibitionQuery = { __typename?: 'Query', exhibition?: { __typename?: 'ExhibitionEntityResponse', data?: { __typename?: 'ExhibitionEntity', attributes?: { __typename?: 'Exhibition', text?: string | null, titel?: string | null } | null } | null } | null };

export type ImageAttributesFragment = { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string };

export type ImageEntityAttributesFragment = { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null };

export type ExhibitAttributesFragment = { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } };

export type ExhibitEntityAttributesFragment = { __typename?: 'ExhibitEntity', id?: string | null, attributes?: { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } } | null };

export type UserExhibitEntityAttributesFragment = { __typename?: 'ExhibitEntity', id?: string | null, attributes?: { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, bids?: { __typename?: 'BidRelationResponseCollection', data: Array<{ __typename?: 'BidEntity', id?: string | null, attributes?: { __typename?: 'Bid', amount: number, createdAt?: any | null, updatedAt?: any | null, users_permissions_user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null } | null }> } | null, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } } | null };

export type ExhibitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExhibitsQuery = { __typename?: 'Query', exhibits?: { __typename?: 'ExhibitEntityResponseCollection', data: Array<{ __typename?: 'ExhibitEntity', id?: string | null, attributes?: { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, total: number, pageSize: number, pageCount: number } } } | null };

export type CategoryExhibitsQueryVariables = Exact<{
  categorySlug: Scalars['String']['input'];
  pagination?: InputMaybe<PaginationArg>;
}>;


export type CategoryExhibitsQuery = { __typename?: 'Query', exhibits?: { __typename?: 'ExhibitEntityResponseCollection', data: Array<{ __typename?: 'ExhibitEntity', id?: string | null, attributes?: { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, total: number, pageSize: number, pageCount: number } } } | null };

export type UserExhibitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserExhibitsQuery = { __typename?: 'Query', userExhibits?: { __typename?: 'ExhibitEntityResponseCollection', data: Array<{ __typename?: 'ExhibitEntity', id?: string | null, attributes?: { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, bids?: { __typename?: 'BidRelationResponseCollection', data: Array<{ __typename?: 'BidEntity', id?: string | null, attributes?: { __typename?: 'Bid', amount: number, createdAt?: any | null, updatedAt?: any | null, users_permissions_user?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', username: string } | null } | null } | null } | null }> } | null, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } } | null }> } | null };

export type ExhibitQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type ExhibitQuery = { __typename?: 'Query', exhibit?: { __typename?: 'ExhibitEntityResponse', data?: { __typename?: 'ExhibitEntity', id?: string | null, attributes?: { __typename?: 'Exhibit', slug: string, title: string, text?: string | null, moretext?: string | null, minimum_amount?: number | null, bidding_step: number, createdAt?: any | null, updatedAt?: any | null, auctionEnabled: boolean, sold: boolean, category?: { __typename?: 'CategoryEntityResponse', data?: { __typename?: 'CategoryEntity', id?: string | null, attributes?: { __typename?: 'Category', name: string, slug: string } | null } | null } | null, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', name: string, caption?: string | null, formats?: any | null, createdAt?: any | null, updatedAt?: any | null, url: string } | null } | null } } | null } | null } | null };

export type GtcQueryVariables = Exact<{ [key: string]: never; }>;


export type GtcQuery = { __typename?: 'Query', gtc?: { __typename?: 'GtcEntityResponse', data?: { __typename?: 'GtcEntity', attributes?: { __typename?: 'Gtc', text?: string | null, titel?: string | null } | null } | null } | null };

export type ImpressumQueryVariables = Exact<{ [key: string]: never; }>;


export type ImpressumQuery = { __typename?: 'Query', impressum?: { __typename?: 'ImpressumEntityResponse', data?: { __typename?: 'ImpressumEntity', attributes?: { __typename?: 'Impressum', text?: string | null, titel?: string | null } | null } | null } | null };

export type LaudatioQueryVariables = Exact<{ [key: string]: never; }>;


export type LaudatioQuery = { __typename?: 'Query', laudatio?: { __typename?: 'LaudatioEntityResponse', data?: { __typename?: 'LaudatioEntity', attributes?: { __typename?: 'Laudatio', text?: string | null, title: string } | null } | null } | null };

export type PolicyQueryVariables = Exact<{ [key: string]: never; }>;


export type PolicyQuery = { __typename?: 'Query', policy?: { __typename?: 'PolicyEntityResponse', data?: { __typename?: 'PolicyEntity', attributes?: { __typename?: 'Policy', text?: string | null, titel?: string | null } | null } | null } | null };

export type RegulationQueryVariables = Exact<{ [key: string]: never; }>;


export type RegulationQuery = { __typename?: 'Query', regulation?: { __typename?: 'RegulationEntityResponse', data?: { __typename?: 'RegulationEntity', attributes?: { __typename?: 'Regulation', text?: string | null, titel?: string | null } | null } | null } | null };

export type PaginationDetailsFragment = { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', page: number, total: number, pageSize: number, pageCount: number } };

export type UserAttributesFragment = { __typename?: 'UsersPermissionsMe', id: string, lastName?: string | null, firstName?: string | null, username: string, email?: string | null, mobile?: string | null, address?: string | null, zip?: string | null, city?: string | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'UsersPermissionsMe', id: string, lastName?: string | null, firstName?: string | null, username: string, email?: string | null, mobile?: string | null, address?: string | null, zip?: string | null, city?: string | null } | null };

export type UpdateMeMutationVariables = Exact<{
  data: UpdateMeInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'UsersPermissionsMe', id: string, lastName?: string | null, firstName?: string | null, username: string, email?: string | null, mobile?: string | null, address?: string | null, zip?: string | null, city?: string | null } | null };

export type LoginMutationVariables = Exact<{
  input: UsersPermissionsLoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, lastName?: string | null, firstName?: string | null, username: string, email?: string | null, mobile?: string | null, address?: string | null, zip?: string | null, city?: string | null } } };

export type RegisterMutationVariables = Exact<{
  input: UsersPermissionsRegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, lastName?: string | null, firstName?: string | null, username: string, email?: string | null, mobile?: string | null, address?: string | null, zip?: string | null, city?: string | null } } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'UsersPermissionsPasswordPayload', ok: boolean } | null };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null, user: { __typename?: 'UsersPermissionsMe', id: string, lastName?: string | null, firstName?: string | null, username: string, email?: string | null, mobile?: string | null, address?: string | null, zip?: string | null, city?: string | null } } | null };

export type VirtualTourQueryVariables = Exact<{ [key: string]: never; }>;


export type VirtualTourQuery = { __typename?: 'Query', virtualTour?: { __typename?: 'VirtualTourEntityResponse', data?: { __typename?: 'VirtualTourEntity', attributes?: { __typename?: 'VirtualTour', title: string, url: string } | null } | null } | null };

export const CategoryAttributesFragmentDoc = gql`
    fragment CategoryAttributes on Category {
  name
  slug
}
    `;
export const CategoryEntityAttributesFragmentDoc = gql`
    fragment CategoryEntityAttributes on CategoryEntity {
  id
  attributes {
    ...CategoryAttributes
  }
}
    ${CategoryAttributesFragmentDoc}`;
export const ImageAttributesFragmentDoc = gql`
    fragment ImageAttributes on UploadFile {
  name
  caption
  formats
  createdAt
  updatedAt
  url
}
    `;
export const ImageEntityAttributesFragmentDoc = gql`
    fragment ImageEntityAttributes on UploadFileEntity {
  id
  attributes {
    ...ImageAttributes
  }
}
    ${ImageAttributesFragmentDoc}`;
export const ExhibitAttributesFragmentDoc = gql`
    fragment ExhibitAttributes on Exhibit {
  slug
  title
  text
  moretext
  minimum_amount
  bidding_step
  createdAt
  updatedAt
  auctionEnabled
  sold
  category {
    data {
      ...CategoryEntityAttributes
    }
  }
  image {
    data {
      ...ImageEntityAttributes
    }
  }
}
    ${CategoryEntityAttributesFragmentDoc}
${ImageEntityAttributesFragmentDoc}`;
export const ExhibitEntityAttributesFragmentDoc = gql`
    fragment ExhibitEntityAttributes on ExhibitEntity {
  id
  attributes {
    ...ExhibitAttributes
  }
}
    ${ExhibitAttributesFragmentDoc}`;
export const BidAttributesFragmentDoc = gql`
    fragment BidAttributes on Bid {
  amount
  createdAt
  updatedAt
  users_permissions_user {
    data {
      id
      attributes {
        username
      }
    }
  }
}
    `;
export const BidEntityAttributesFragmentDoc = gql`
    fragment BidEntityAttributes on BidEntity {
  id
  attributes {
    ...BidAttributes
  }
}
    ${BidAttributesFragmentDoc}`;
export const UserExhibitEntityAttributesFragmentDoc = gql`
    fragment UserExhibitEntityAttributes on ExhibitEntity {
  id
  attributes {
    ...ExhibitAttributes
    bids(sort: ["amount:desc"], pagination: {limit: 1}) {
      data {
        ...BidEntityAttributes
      }
    }
  }
}
    ${ExhibitAttributesFragmentDoc}
${BidEntityAttributesFragmentDoc}`;
export const PaginationDetailsFragmentDoc = gql`
    fragment PaginationDetails on ResponseCollectionMeta {
  pagination {
    page
    total
    pageSize
    pageCount
  }
}
    `;
export const UserAttributesFragmentDoc = gql`
    fragment UserAttributes on UsersPermissionsMe {
  id
  lastName
  firstName
  username
  email
  mobile
  address
  zip
  city
}
    `;
export const TwilioVerifySettingsDocument = gql`
    query TwilioVerifySettings {
  twilioVerifySettings {
    enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TwilioVerifySettingsGQL extends Apollo.Query<TwilioVerifySettingsQuery, TwilioVerifySettingsQueryVariables> {
    override document = TwilioVerifySettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBidTokenDocument = gql`
    mutation CreateBidToken {
  createTwilioVerifyVerification {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBidTokenGQL extends Apollo.Mutation<CreateBidTokenMutation, CreateBidTokenMutationVariables> {
    override document = CreateBidTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExhibitBidsDocument = gql`
    query ExhibitBids($filterInput: BidFiltersInput!, $pageSize: Int!) {
  bids(
    filters: $filterInput
    sort: ["updatedAt:desc"]
    pagination: {page: 0, pageSize: $pageSize}
  ) {
    data {
      ...BidEntityAttributes
    }
    meta {
      ...PaginationDetails
    }
  }
}
    ${BidEntityAttributesFragmentDoc}
${PaginationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExhibitBidsGQL extends Apollo.Query<ExhibitBidsQuery, ExhibitBidsQueryVariables> {
    override document = ExhibitBidsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBidTransactionalDocument = gql`
    mutation CreateBidTransactional($input: BidInput!) {
  createBidTransactional(data: $input) {
    data {
      ...BidEntityAttributes
    }
  }
}
    ${BidEntityAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBidTransactionalGQL extends Apollo.Mutation<CreateBidTransactionalMutation, CreateBidTransactionalMutationVariables> {
    override document = CreateBidTransactionalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoriesDocument = gql`
    query Categories {
  categories(sort: "order:asc") {
    data {
      id
      attributes {
        ...CategoryAttributes
      }
    }
  }
}
    ${CategoryAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoriesGQL extends Apollo.Query<CategoriesQuery, CategoriesQueryVariables> {
    override document = CategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactDocument = gql`
    query Contact {
  contact {
    data {
      attributes {
        text
        titel
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactGQL extends Apollo.Query<ContactQuery, ContactQueryVariables> {
    override document = ContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExhibitionDocument = gql`
    query Exhibition {
  exhibition {
    data {
      attributes {
        text
        titel
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExhibitionGQL extends Apollo.Query<ExhibitionQuery, ExhibitionQueryVariables> {
    override document = ExhibitionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExhibitsDocument = gql`
    query Exhibits {
  exhibits {
    data {
      ...ExhibitEntityAttributes
    }
    meta {
      ...PaginationDetails
    }
  }
}
    ${ExhibitEntityAttributesFragmentDoc}
${PaginationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExhibitsGQL extends Apollo.Query<ExhibitsQuery, ExhibitsQueryVariables> {
    override document = ExhibitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoryExhibitsDocument = gql`
    query CategoryExhibits($categorySlug: String!, $pagination: PaginationArg = {page: 1, pageSize: 45}) {
  exhibits(
    filters: {sold: {eq: false}, category: {slug: {eq: $categorySlug}}}
    sort: ["order:asc, slug:asc"]
    pagination: $pagination
  ) {
    data {
      ...ExhibitEntityAttributes
    }
    meta {
      ...PaginationDetails
    }
  }
}
    ${ExhibitEntityAttributesFragmentDoc}
${PaginationDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryExhibitsGQL extends Apollo.Query<CategoryExhibitsQuery, CategoryExhibitsQueryVariables> {
    override document = CategoryExhibitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UserExhibitsDocument = gql`
    query UserExhibits {
  userExhibits {
    data {
      ...UserExhibitEntityAttributes
    }
  }
}
    ${UserExhibitEntityAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserExhibitsGQL extends Apollo.Query<UserExhibitsQuery, UserExhibitsQueryVariables> {
    override document = UserExhibitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExhibitDocument = gql`
    query Exhibit($slug: String!) {
  exhibit(slug: $slug) {
    data {
      ...ExhibitEntityAttributes
    }
  }
}
    ${ExhibitEntityAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExhibitGQL extends Apollo.Query<ExhibitQuery, ExhibitQueryVariables> {
    override document = ExhibitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GtcDocument = gql`
    query Gtc {
  gtc {
    data {
      attributes {
        text
        titel
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GtcGQL extends Apollo.Query<GtcQuery, GtcQueryVariables> {
    override document = GtcDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ImpressumDocument = gql`
    query Impressum {
  impressum {
    data {
      attributes {
        text
        titel
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ImpressumGQL extends Apollo.Query<ImpressumQuery, ImpressumQueryVariables> {
    override document = ImpressumDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LaudatioDocument = gql`
    query Laudatio {
  laudatio {
    data {
      attributes {
        text
        title
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LaudatioGQL extends Apollo.Query<LaudatioQuery, LaudatioQueryVariables> {
    override document = LaudatioDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PolicyDocument = gql`
    query Policy {
  policy {
    data {
      attributes {
        text
        titel
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PolicyGQL extends Apollo.Query<PolicyQuery, PolicyQueryVariables> {
    override document = PolicyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegulationDocument = gql`
    query Regulation {
  regulation {
    data {
      attributes {
        text
        titel
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RegulationGQL extends Apollo.Query<RegulationQuery, RegulationQueryVariables> {
    override document = RegulationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MeDocument = gql`
    query Me {
  me {
    ...UserAttributes
  }
}
    ${UserAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MeGQL extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMeDocument = gql`
    mutation UpdateMe($data: UpdateMeInput!) {
  updateMe(data: $data) {
    ...UserAttributes
  }
}
    ${UserAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeGQL extends Apollo.Mutation<UpdateMeMutation, UpdateMeMutationVariables> {
    override document = UpdateMeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      ...UserAttributes
    }
  }
}
    ${UserAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegisterDocument = gql`
    mutation Register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      ...UserAttributes
    }
  }
}
    ${UserAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RegisterGQL extends Apollo.Mutation<RegisterMutation, RegisterMutationVariables> {
    override document = RegisterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    override document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $code: String!) {
  resetPassword(password: $password, passwordConfirmation: $password, code: $code) {
    jwt
    user {
      ...UserAttributes
    }
  }
}
    ${UserAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    override document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VirtualTourDocument = gql`
    query VirtualTour {
  virtualTour {
    data {
      attributes {
        title
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VirtualTourGQL extends Apollo.Query<VirtualTourQuery, VirtualTourQueryVariables> {
    override document = VirtualTourDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }