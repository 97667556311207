<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="formrow">
    <label class="form-label">Neues Passwort</label>
    <input type="password" formControlName="password" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
    <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
      <div *ngIf="f['password'].errors['required']">Neues Passwort ist Pflicht</div>
      <div *ngIf="f['password'].errors['minlength']">Dein Passwort muss mindestens 6 Zeichen haben</div>
    </div>
  </div>
  <div class="formrow">
    <button>NEUES PASSWORT SETZEN</button>
  </div>
  <div class="formrow">
    <a routerLink="/">ZURÜCK ZUM LOGIN?</a>
  </div>
</form>