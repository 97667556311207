import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, catchError, map, tap } from 'rxjs';
import { CreateBidTokenGQL, CreateTwilioVerifyVerificationResponse, TwilioVerifySettingsGQL } from 'src/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class TanService {

  constructor(
    private twilioVerifySettingsGql: TwilioVerifySettingsGQL,
    private createBidTokenGql: CreateBidTokenGQL,
  ) { }

  getEnabled(): Observable<boolean> {
    return this.twilioVerifySettingsGql.watch().valueChanges.pipe(
      catchError(err => {
        throw new Error(err.message)
      }),
      map(response => {
        if (!response.data || !response.data.twilioVerifySettings) {
          throw new Error('No Data');
        }

        return response.data.twilioVerifySettings?.enabled
      })
    )
  }

  create(): Observable<string | void> {
    return this.createBidTokenGql.mutate().pipe(
      map(response => {
        if (!response.data) {
          throw new Error('No Data');
        }

        return
      }),
      catchError(err => {
        if (err.message.match(/max send attempts reached/i)) {
          return 'Verwende den Code, den du bereits erhalten hast oder warte 10 Minuten um einen neuen Code anzufordern';
        } else {
          throw new Error(err.message);
        }
      }),
    )
  }
}
