import { HttpErrorResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { rollbarFactory } from './rollbar';

const uri = environment.graphQLUrl;
export function createApollo(httpLink: HttpLink, toastr: ToastrService): ApolloClientOptions<any> {

  const rollbar = rollbarFactory();

  const auth = setContext((operation, context) => {
    const jwt = localStorage.getItem('jwt');

    if (jwt === null) {
      return {};
    }

    return {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
  });

  const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        rollbar.error(message, { location: locations, path: path })
      });
    }


    if (networkError) {
      const networkErrorRef: HttpErrorResponse = networkError as HttpErrorResponse;
      if (networkErrorRef && networkErrorRef.status === 401) {
        localStorage.removeItem('jwt');
        localStorage.removeItem('account');
        toastr.error('Ein Problem ist aufgetreten, bitte melde dich erneut an!')
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toastr.error("Daten können aktuell nicht geladen werden!");
        rollbar.error(networkError);
      }
    }
  });

  const inMemoryCache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          exhibits: {
            keyArgs: ['filters'],
            merge: (existing = { data: [] }, incoming) => {
              return {
                ...incoming,
                data: [...existing.data, ...incoming.data]
              }
            },
          }
        }
      },
    }
  });

  return {
    link: ApolloLink.from([errorHandler, auth, httpLink.create({ uri })]),
    cache: inMemoryCache
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ToastrService],
    },
  ],
})
export class GraphQLModule { }
