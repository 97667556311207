import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService } from '@services/account.service';
import { ToastrService } from 'ngx-toastr';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private toastr: ToastrService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams['confirmed']) {
      this.toastr.success('Danke für die Registrierung!')
    }
    this.form = this.formBuilder.group({
      identifier: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.viewportScroller.scrollToPosition([0, 0]);
        }, 50);
      }
    })
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.accountService.login(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Eingeloggt!');
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.router.navigateByUrl(returnUrl);
        },
        error: (error) => {
          this.toastr.error(error.message);
        }
      });
  }
}