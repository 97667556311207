import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Exhibit, Bid } from '@app/_models';
import { AccountService, ExhibitsService } from '@app/_services';
import { Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-exhibit',
  templateUrl: './exhibit.component.html',
  styleUrls: ['./exhibit.component.scss']
})
export class ExhibitComponent implements OnInit {

  exhibitId?: string | number;
  categoryName: string = '';
  categorySlug: string = '';
  lastBidRefresh: Date = new Date();
  exhibit$: Observable<Exhibit> = of();
  bid$: Observable<Bid> = of();
  account = this.accountService.accountValue;
  currentUrl = `/${this.route.snapshot.url.join('/')}`;

  constructor(
    private route: ActivatedRoute,
    private exhibitsService: ExhibitsService,
    private accountService: AccountService,
    private viewportScroller: ViewportScroller
    ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.categorySlug = params['categorySlug'];
      this.exhibit$ = this.exhibitsService.get(params['exhibitSlug']).pipe(
        tap(exhibit => {
          setTimeout(() => {
            this.viewportScroller.scrollToAnchor(`exhibit-${exhibit.slug}`);
          }, 100);
        })
      );
    });
  }

  navigateBack(): void {
    history.back();
  }
}
