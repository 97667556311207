import { Injectable } from '@angular/core';
import { Bid, Category, CollectionResponse, Exhibit, StrapiImage, UserExhibit } from '@app/_models';
import { environment } from '@env/environment';
import { QueryRef } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
  BidEntityAttributesFragment,
  CategoryEntityAttributesFragment,
  CategoryExhibitsGQL,
  CategoryExhibitsQuery,
  CategoryExhibitsQueryVariables,
  ExhibitEntityAttributesFragment,
  ExhibitGQL,
  ImageEntityAttributesFragment,
  UserExhibitEntityAttributesFragment,
  UserExhibitsGQL,
  UserExhibitsQuery,
  UserExhibitsQueryVariables,
} from 'src/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class ExhibitsService {

  constructor(
    private categoryExhibitsGql: CategoryExhibitsGQL,
    private userExhibitsGql: UserExhibitsGQL,
    private exhibitGql: ExhibitGQL,
  ) { }

  forCategory(categorySlug: string, page: number = 1): Observable<CollectionResponse<Exhibit>> {
    return this.categoryExhibitsQuery(categorySlug).valueChanges.pipe(
      map(response => {
        const exhibits = response.data.exhibits?.data || []
        return {
          data: exhibits.map(exhibitData => this.transformToExhibit(exhibitData)),
          meta: response.data.exhibits?.meta
        }
      })
    )
  }

  loadMoreExhibits(categorySlug: string, page: number): void {
    this.categoryExhibitsQuery(categorySlug)
    .fetchMore({
      variables: {
        pagination: { page: page, pageSize: environment.exhibitsPageSize }
      }
    })
  }

  forUser(): Observable<UserExhibit[]> {
    return this.userExhibitsQuery().valueChanges.pipe(
      map(response => {
        const exhibits = response.data.userExhibits?.data || []
        return exhibits.map(exhibitData => this.transformToUserExhibit(exhibitData));
      })
    )
  }

  get(slug: string): Observable<Exhibit> {
    return this.exhibitGql.watch({ slug: slug }).valueChanges.pipe(
      map(response => {
        const exhibitData = response.data.exhibit?.data
        return this.transformToExhibit(exhibitData);
      })
    )
  }

  userExhibitsQuery(): QueryRef<UserExhibitsQuery, UserExhibitsQueryVariables> {
    return this.userExhibitsGql.watch();
  }

  private transformToExhibit(exhibitData?: ExhibitEntityAttributesFragment | null): Exhibit {
    if (!exhibitData || !exhibitData.id || !exhibitData.attributes || !exhibitData.attributes.category) {
      throw new Error(`No Exhibit data: ${exhibitData}`);
    }
    const attributes = exhibitData.attributes;

    return {
      id: exhibitData.id,
      slug: attributes.slug,
      title: attributes.title,
      text: attributes.text,
      moretext: attributes.moretext,
      minimum_amount: attributes.minimum_amount,
      bidding_step: attributes.bidding_step,
      auctionEnabled: attributes.auctionEnabled,
      sold: attributes.sold,
      createdAt: attributes.createdAt,
      updatedAt: attributes.updatedAt,
      category: this.transformToCategory(attributes.category?.data),
      image: this.transformToImage(attributes.image.data),
    }
  }

  private transformToUserExhibit(exhibitData?: UserExhibitEntityAttributesFragment | null): UserExhibit {
    if (!exhibitData || !exhibitData.attributes?.bids?.data[0]) {
      throw new Error(`No UserExhibit data: ${exhibitData}`);
    }
    const attributes = exhibitData.attributes;
    const exhibit = this.transformToExhibit(exhibitData);

    return {
      ...exhibit,
      bid: this.transformToBid(attributes.bids?.data[0])
    }
  }

  private transformToCategory(categoryData?: CategoryEntityAttributesFragment | null): Category {
    if (!categoryData || !categoryData.id || !categoryData.attributes) {
      throw new Error(`No Category data: ${categoryData}`)
    }

    return {
      id: categoryData.id,
      name: categoryData.attributes.name,
      slug: categoryData.attributes.slug
    }
  }

  private transformToImage(imageData?: ImageEntityAttributesFragment | null): StrapiImage {
    if (!imageData || !imageData.attributes) {
      throw new Error(`No Image data: ${imageData}`);
    }

    return {
      name: imageData.attributes.name,
      caption: imageData.attributes.caption,
      formats: imageData.attributes.formats,
      url: imageData.attributes.url,
    }
  }

  // TODO: extract shared with BidService
  private transformToBid(bidData?: BidEntityAttributesFragment | null): Bid {
    if (!bidData || !bidData.id || !bidData.attributes || !bidData.attributes.users_permissions_user?.data) {
      throw new Error(`No Bid data: ${bidData}`);
    }

    const attributes = bidData.attributes;
    const userAttributes = bidData.attributes.users_permissions_user.data

    return {
      id: bidData.id,
      amount: attributes.amount,
      createdAt: attributes.createdAt,
      updatedAt: attributes.updatedAt,
      user: this.transformToUser(userAttributes)
    }
  }

  private transformToUser(userData: { id?: string | null, attributes?: { username: string } | null }): { id: string, username: string } {
    if (!userData.id || !userData?.attributes) {
      throw new Error(`No associated user data: ${userData}`)
    }
    return {
      id: userData.id,
      username: userData.attributes.username
    }
  }

  private categoryExhibitsQuery(categorySlug: string): QueryRef<CategoryExhibitsQuery, CategoryExhibitsQueryVariables> {
    return this.categoryExhibitsGql.watch({
      categorySlug: categorySlug
    });
  }
}