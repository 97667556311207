import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { first, take, tap } from 'rxjs/operators';

import { AccountService } from '@app/_services';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  submitted = false;
  mobileAvailableCountries: CountryISO[] = [CountryISO.Switzerland, CountryISO.Germany];
  switzerland: CountryISO = CountryISO.Switzerland;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      mobile: ['', Validators.required],
      gtcAccepted: ['', Validators.required],
    });
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const input = {
      ...this.form.value,
      mobile: this.form.controls['mobile'].value.internationalNumber
    }

    this.accountService.register(input)
      .pipe(
        first(),
      ).subscribe({
        next: () => {
          this.toastr.success('Bitte bestätige die Registrierung mit dem erhaltenen Email'),
            this.router.navigate(['/register_success'], { queryParams: { email: this.form.controls['email'].value } });
        },
        error: error => {
          this.toastr.error(error.message);
        }
      });
  }

  hasError(controlName: string): boolean {
    const control = this.form.controls[controlName];
    return control.invalid && (control.dirty && control.touched)
  }
}
