import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService } from '@app/_services';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loginLink: { path: string, text: string } = {
    path: 'login',
    text: 'ANMELDEN'
  }
  account$: Observable<Account | null> = of();

  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {
  }

  ngOnInit(): void {
    this.account$ = this.accountService.account;
    this.setLoginLink(this.router.url);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setLoginLink(event.url);
      }
    })
  }

  setLoginLink(url: string): void {
    switch (true) {
      case /\/login.*/.test(url):
        this.loginLink = {
          path: '',
          text: 'ANMELDEN'
        }
        break;
      case /\/register.*/.test(url):
        this.loginLink = {
          path: '',
          text: 'REGISTRIEREN'
        }
        break;
      case /\/forgot.*/.test(url):
        this.loginLink = {
          path: '',
          text: 'PASSWORT ZURÜCKSETZEN'
        }
        break;
      case /\/reset.*/.test(url):
        this.loginLink = {
          path: '',
          text: 'PASSWORT ZURÜCKSETZEN'
        }
        break;
      default:
        this.loginLink = {
          path: 'login',
          text: 'ANMELDEN'
        }
        break;
    }
  }
}