import { Component, OnInit } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { VirtualTourGQL } from 'src/generated/graphql';

@Component({
  selector: 'app-virtual-tour',
  templateUrl: './virtual-tour.component.html',
  styleUrls: ['./virtual-tour.component.scss']
})
export class VirtualTourComponent implements OnInit {

  constructor(private virtualTourGql: VirtualTourGQL) {}

  virtualTour$: Observable<any> = of();

  ngOnInit(): void {
    this.virtualTour$ = this.virtualTourGql.watch().valueChanges.pipe(
      map(response => {
        return response.data.virtualTour
      })
    )
  }
}
