<ul class="menu">
  <li *ngFor="let category of categories$ | async">
    <h1>
      <a class="category-link" [routerLink]="categoryLink(category)">{{ category.name }}</a>
    </h1>
    <ng-container *ngIf="categorySlug == category.slug">
      <div class="category-container" infiniteScroll [infiniteScrollDistance]="2" (scrolled)="loadNextPage()">
        <ng-container *ngIf="(exhibits$ | async) as exhibits">
          <div *ngIf="!exhibits.length" class="account_item_sp">
            <span>In dieser Kategorie gibt es keine Bilder</span>
          </div>
          <ng-container *ngFor="let exhibit of exhibits">
            <div id="{{ exhibit.slug }}" class="exhibit-container" routerLink="/{{ categorySlug }}/{{ exhibit.slug }}">
              <div class="exhibit_thumb">
                <img src="{{ exhibit.image.formats.small.url }}" class="img_thumb">
              </div>
              <h3 class="exhibit-title">{{ exhibit.title }}</h3>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </li>
</ul>