import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ApolloModule } from 'apollo-angular'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { GraphQLModule } from './graphql.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { CategoryComponent } from './category/category.component';
import { ExhibitionComponent } from './exhibition/exhibition.component';
import { RegulationComponent } from './regulation/regulation.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { GtcComponent } from './gtc/gtc.component';
import { PolicyComponent } from './policy/policy.component';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { BidsModule } from './bids/bids.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { ExhibitComponent } from './exhibit/exhibit.component';
import { HomeComponent } from './home/home.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { GtcStandaloneComponent } from './gtc-standalone/gtc-standalone.component';
import { PolicyStandaloneComponent } from './policy-standalone/policy-standalone.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { AccountBidsComponent } from './account-bids/account-bids.component';
import { VirtualTourComponent } from './virtual-tour/virtual-tour.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { LaudatioComponent } from './laudatio/laudatio.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AccountDetailComponent,
    AccountBidsComponent,
    CategoryComponent,
    ExhibitionComponent,
    ExhibitComponent,
    RegulationComponent,
    ContactComponent,
    ImpressumComponent,
    GtcComponent,
    PolicyComponent,
    HomeComponent,
    PasswordForgotComponent,
    PasswordResetComponent,
    GtcStandaloneComponent,
    PolicyStandaloneComponent,
    VirtualTourComponent,
    RegisterSuccessComponent,
    LaudatioComponent,
  ],
  imports: [
    ApolloModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GraphQLModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    InfiniteScrollModule,
    BidsModule,
    ToastNoAnimationModule.forRoot(
      {
        preventDuplicates: true,
        timeOut: 4000,
        positionClass: 'toast-bottom-center',
      }
    ),
  ],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }