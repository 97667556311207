<div class="padded-content bottom-border">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="formrow">
      <label>Vorname</label>
      <input type="text" formControlName="firstName" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }" />
      <div *ngIf="submitted && f['firstName'].errors" class="invalid-feedback">
        <div *ngIf="f['firstName'].errors['required']">Der Vorname ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label>Nachname</label>
      <input type="text" formControlName="lastName" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }" />
      <div *ngIf="submitted && f['lastName'].errors" class="invalid-feedback">
        <div *ngIf="f['lastName'].errors['required']">Der Nachname ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label>Email</label>
      <input type="email" formControlName="email" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
      <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
        <div *ngIf="f['email'].errors['required']">Die Email ist Pflicht</div>
        <div *ngIf="f['email'].errors['email']">Die Email ist nicht korrekt</div>
      </div>
    </div>
    <div class="formrow">
      <label>Benutzername</label>
      <input type="text" formControlName="username" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
      <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
        <div *ngIf="f['username'].errors['required']">Die Email ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label>Passwort</label>
      <input type="password" formControlName="password" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
        <div *ngIf="f['password'].errors['required']">Passwort ist Pflicht</div>
        <div *ngIf="f['password'].errors['minlength']">Dein Passwort muss mindestens 6 Zeichen haben</div>
      </div>
    </div>
    <div class="formrow">
      <label>Adresse</label>
      <input type="text" formControlName="address" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['address'].errors }" />
      <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
        <div *ngIf="f['address'].errors['required']">Adresse ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label>PLZ</label>
      <input type="text" formControlName="zip" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['zip'].errors }" />
      <div *ngIf="submitted && f['zip'].errors" class="invalid-feedback">
        <div *ngIf="f['zip'].errors['required']">PLZ ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label>Ort</label>
      <input type="text" formControlName="city" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['city'].errors }" />
      <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
        <div *ngIf="f['city'].errors['required']">Ort ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label>Mobile</label>
      <ngx-intl-tel-input [onlyCountries]="mobileAvailableCountries"
        [selectedCountryISO]="switzerland"
        [ngClass]="{ 'is-invalid': submitted && f['mobile'].errors }"
        [cssClass]="(submitted && f['mobile'].errors) ? 'form-control is-invalid' : 'form-control'"
        [phoneValidation]="true" [inputId]="'mobile'" name="mobile" formControlName="mobile"></ngx-intl-tel-input>
      <div *ngIf="submitted && f['mobile'].errors" class="invalid-feedback">
        <div *ngIf="f['mobile'].errors['required']">Deine Mobilenummer ist Pflicht</div>
        <div *ngIf="f['mobile'].errors['validatePhoneNumber']">Die Mobilenummer ist ungültig</div>
      </div>
    </div>
    <div class="formrow">
      <div class="gtc-checkbox">
        <input type="checkbox" formControlName="gtcAccepted" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['gtcAccepted'].errors }" />
        <span>Ich akzeptiere die <a class="legal-link" routerLink="/agb" target="_blank">ALLGEMEINEN BESTIMMUNGEN</a>
          und die <a class="legal-link" routerLink="/datenschutz" target="_blank">DATENSCHUTZERKLÄRUNG</a>.</span>
      </div>
      <div *ngIf="submitted && f['gtcAccepted'].errors" class="invalid-feedback">
        <div *ngIf="f['gtcAccepted'].errors['required']">Bitte lies die Regeln und erkläre Dich damit einverstanden.
        </div>
      </div>
    </div>
    <div class="formrow">
      <button>REGISTRIEREN</button>
    </div>
    <div class="formrow">
      <a routerLink="/login">ZURÜCK ZUM LOGIN?</a>
    </div>
  </form>
</div>