<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="formrow">
    <label class="form-label">Email</label>
    <input type="email" formControlName="email" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
      <div *ngIf="f['email'].errors['required']">Email ist Pflicht</div>
    </div>
  </div>
  <div class="formrow">
    <button>
      PASSWORT ZURÜCKSETZEN
    </button>
  </div>
  <div class="formrow">
    <a routerLink="/login">ZURÜCK ZUM LOGIN?</a>
  </div>
</form>