import { Component, OnInit } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { GtcGQL} from 'src/generated/graphql';

@Component({
  selector: 'app-gtc',
  templateUrl: './gtc.component.html',
  styleUrls: ['./gtc.component.scss']
})
export class GtcComponent implements OnInit {

  gtc$: Observable<any> = of();
  constructor(private gtcGql: GtcGQL) { }

  ngOnInit(): void {
    this.gtc$ = this.gtcGql.watch().valueChanges.pipe(
      map(response => {
        return (response.data.gtc)
      })
    )
  }

}