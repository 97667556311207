import Rollbar from 'rollbar';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { environment } from '@env/environment';

const rollbarConfig = {
  accessToken: 'b29c596ae1784dfea1d332c728afb6db',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.production ? 'production' : 'development',
  enabled: environment.production ? true : false,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment.version,
        guess_uncaught_frames: true
      }
    }
  }
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
