<div>
  <ng-container *ngIf="(bid$ | async) as bid ;else startbid">
    <div>
      <p>
        Höchstes Gebot:
      </p>
      <p>
        CHF {{ bid.amount }}.— exkl. MWST. {{ (bid.user?.id == account?.id) ? '(Dein Gebot)' : '(NICHT Dein Gebot)' }}
      </p>
    </div>
  </ng-container>
  <ng-template #startbid>
    <div>
      <p>
        Das Startgebot:
      </p>
      <p>
        CHF {{exhibit.minimum_amount}}.— exkl. MWST.
      </p>
    </div>
  </ng-template>
  <div>
    <span class="top"><a (click)="refreshBids()">NEU LADEN&nbsp;&#8635;</a></span>
  </div>
</div>