import { Component } from '@angular/core';

@Component({
  selector: 'app-policy-standalone',
  templateUrl: './policy-standalone.component.html',
  styleUrls: ['./policy-standalone.component.scss']
})
export class PolicyStandaloneComponent {

}
