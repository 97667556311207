import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExhibitBidComponent } from '@app/bids/exhibit-bid/exhibit-bid.component';
import { BidsFormComponent } from '@app/bids/form/form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApolloModule } from 'apollo-angular';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    ExhibitBidComponent,
    BidsFormComponent,
  ],
  imports: [
    CommonModule,
    ApolloModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ExhibitBidComponent,
    BidsFormComponent,
  ]
})
export class BidsModule { }
