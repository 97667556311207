import { Component, OnInit } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { LaudatioGQL} from 'src/generated/graphql';

@Component({
  selector: 'app-laudatio',
  templateUrl: './laudatio.component.html',
  styleUrls: ['./laudatio.component.scss']
})
export class LaudatioComponent implements OnInit {

  laudatio$: Observable<any> = of();
  constructor(private laudatioGql: LaudatioGQL) { }

  ngOnInit(): void {
    this.laudatio$ = this.laudatioGql.watch().valueChanges.pipe(
      map(response => {
        return (response.data.laudatio)
      })
    )
  }
}
