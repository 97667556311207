<div class="padded-content bottom-border">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="formrow">
      <label class="form-label">Benutzername / Email</label>
      <input type="email" formControlName="identifier" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['identifier'].errors }" />
      <div *ngIf="submitted && f['identifier'].errors" class="invalid-feedback">
        <div *ngIf="f['identifier'].errors['required']">Email oder Benutzername ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <label class="form-label">Passwort</label>
      <input type="password" formControlName="password" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
      <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
        <div *ngIf="f['password'].errors['required']">Passwort ist Pflicht</div>
      </div>
    </div>
    <div class="formrow">
      <button type="submit" class="submit">
        JETZT ANMELDEN
      </button>
    </div>
    <div class="formrow">
      <a routerLink="/forgot">PASSWORT VERGESSEN?</a>
    </div>
  </form>
  <div class="formrow">
    Bist Du noch nicht registriert? <br>Um mit zu bieten, muss Du dich <button routerLink="/register">HIER
      REGISTRIEREN</button>.
  </div>
</div>