import { Component, OnInit } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { ImpressumGQL} from 'src/generated/graphql';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  impressum$: Observable<any> = of();
  constructor(private impressumGql: ImpressumGQL) { }

  ngOnInit(): void {
    this.impressum$ = this.impressumGql.watch().valueChanges.pipe(
      map(response => {
        return (response.data.impressum)
      })
    )
  }

}