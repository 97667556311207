import { Injectable } from '@angular/core';
import { Bid } from '@app/_models';
import { environment } from '@env/environment';
import { QueryRef } from 'apollo-angular';
import { Observable, catchError, map, tap } from 'rxjs';
import { ExhibitsService } from './exhibits.service';
import {
  BidEntityAttributesFragment,
  BidFiltersInput,
  BidInput,
  CreateBidTransactionalGQL,
  Exact,
  ExhibitBidsGQL,
  ExhibitBidsQuery,
  ExhibitBidsQueryVariables,
} from 'src/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class BidsService {
  constructor(
    private exhibitBidsGql: ExhibitBidsGQL,
    private createBidTransactionalGql: CreateBidTransactionalGQL,
    private exhibitsService: ExhibitsService
  ) { }


  forExhibit(exhibitId: string | number): Observable<Bid> {
    return this.exhibitBidsQuery(exhibitId).valueChanges.pipe(
      map(response => {
        const bids = response.data.bids?.data || []
        return bids.map(bidData => {
          return this.transformToBid(bidData)
        });
      }),
      map((bids: Bid[]) => bids[0]),
      catchError((error) => {
        throw new Error(error.message)
      }),
    )
  }

  create(bidInput: BidInput): Observable<Bid> {
    if (!bidInput.exhibit) {
      throw new Error("BidInput must contain exhibit reference")
    }
    const exhibitId = bidInput.exhibit

    const variables = { input: bidInput }
    return this.createBidTransactionalGql.mutate(variables).pipe(
      map(response => {
        if (!response.data?.createBidTransactional?.data) {
          throw new Error('Invalid Bid value')
        }
        return this.transformToBid(response.data?.createBidTransactional.data)
      }),
      catchError(err => {
        if (err.message.match(/verify otp code/i)) {
          throw new Error('SMS Code kann nicht verifiziert werden');
        };

        if (err.graphQLErrors[0]?.extensions['code'] == 'STRAPI_BIDDING_ERROR') {
          const errorDetails = err.graphQLErrors[0].extensions['error'].details;
          switch (errorDetails.code) {
            case 'ERR_AUCTION_DISABLED':
              throw new Error('Die Auktion ist beendet.');
            case 'ERR_AMOUNT_BELOW_MINIMUM':
              throw new Error(`Betrag muss höher sein als das Mindestgebot (CHF ${errorDetails.minimum_amount}).`)
            case 'ERR_AMOUNT_TOO_LOW':
              throw new Error(`Betrag muss mindestens CHF ${errorDetails.minimum_amount} betragen.`)
            default:
              throw new Error('Gebot fehlgeschlagen!')
          }
        };

        if (err.networkError?.error?.errors?.[0]?.message) {
          const errorMessage = err.networkError.error.errors[0].message;
          if (errorMessage.match(/non-integer/)) {
            throw new Error('Betrag darf keine Rappen beinhalten');
          };
          if (errorMessage.match(/cannot represent non 32-bit signed integer/)) {
            throw new Error('Betrag darf nicht in Milliardenhöhe sein');
          }
          throw new Error(errorMessage);
        };

        throw new Error(err.message)
      }),
      tap(_response => {
        this.exhibitBidsQuery(exhibitId).refetch();
        this.exhibitsService.userExhibitsQuery().refetch()
      }),
    )
  }

  reloadForExhibit(exhibitId: string | number): void {
    this.exhibitBidsQuery(exhibitId).refetch();
  }

  private transformToBid(bidData: BidEntityAttributesFragment | null): Bid {
    if (!bidData || !bidData.id || !bidData.attributes || !bidData.attributes.users_permissions_user?.data) {
      throw new Error(`Missing Bid data: ${bidData}`);
    }

    const attributes = bidData.attributes;
    const userAttributes = bidData.attributes.users_permissions_user.data

    const bid: Bid = {
      id: bidData.id,
      amount: attributes.amount,
      createdAt: attributes.createdAt,
      updatedAt: attributes.updatedAt,
      user: this.transformToUser(userAttributes)
    }

    return bid;
  }

  private transformToUser(userData: { id?: string | null, attributes?: { username: string } | null }): { id: string, username: string } {
    if (!userData.id || !userData?.attributes) {
      throw new Error(`No associated user data: ${userData}`)
    }
    return {
      id: userData.id,
      username: userData.attributes.username
    }
  }

  private exhibitBidsQuery(exhibitId: string | number): QueryRef<ExhibitBidsQuery, ExhibitBidsQueryVariables> {
    return this.exhibitBidsGql.watch(this.exhibitBidsQueryVariables(exhibitId));
  }

  private exhibitBidsQueryVariables(exhibitId: string | number): Exact<{ filterInput: BidFiltersInput, pageSize: number }> {
    return {
      filterInput: { exhibit: { id: { eq: exhibitId } } },
      pageSize: environment.bidsPageSize
    }
  }
}
