import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category, Exhibit } from '@app/_models';
import { ExhibitsService } from '@app/_services';
import { Observable, map, of, tap } from 'rxjs';
import { CategoriesGQL } from 'src/generated/graphql';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  exhibitPage: number = 1;
  maxPages: number = 0;
  anchorScrollDisabled = false;
  categoryName: string = '';
  categorySlug: string = '';
  categories$: Observable<Category[]> = of([]);
  exhibits$: Observable<Exhibit[]> = of([]);

  constructor(
    private route: ActivatedRoute,
    private categoriesGQL: CategoriesGQL,
    private exhibitsService: ExhibitsService,
    ) {}


  ngOnInit(): void {
    this.categories$ = this.watchCategories();

    const fragment = this.route.snapshot.fragment;
    this.route.params.subscribe(params => {
      this.categorySlug = params['categorySlug'];
      if (this.categorySlug) {
        this.exhibits$ = this.exhibitsService.forCategory(this.categorySlug).pipe(
          tap(res => {
            if (res.meta?.pagination.pageCount) {
              this.maxPages = res.meta.pagination.pageCount;
            }
            if (res.meta?.pagination.page) {
              this.exhibitPage = res.meta.pagination.page
            }
          }),
          map(response => response.data),
          tap(exhibits => {
            if (!fragment || this.anchorScrollDisabled) {
              return;
            }

            const matchingExhibit = exhibits.find(exhibit => exhibit.slug == fragment);
            if(matchingExhibit) {
              setTimeout(() => {
                const element = document.querySelector(`#${fragment}`);
                if (element) {
                  element.scrollIntoView(true)
                  this.anchorScrollDisabled = true;
                } else {
                  this.loadNextPage();
                }
              }, 400);
            } else {
              this.loadNextPage();
            }
          })
        )
      } else {
        this.exhibits$ = of([]);
      }
    })
  }

  categoryLink(category: Category): string {
    let path: string = '/';
    if (this.categorySlug != category.slug) {
      path = `/${category.slug}`;
    }
    return path;
  }

  watchCategories(): Observable<Category[]> {
    return this.categoriesGQL.watch().valueChanges.pipe(
      map(response => {
        if (response.errors) {
          throw response.errors;
        };
        const categories = response.data.categories?.data || [];
        return categories.map(categoryData => {
          if (!categoryData || !categoryData.id || !categoryData.attributes) {
            throw new Error(`No Category data: ${categoryData}`);
          }
          return {
            id: categoryData.id,
            name: categoryData.attributes.name,
            slug: categoryData.attributes.slug
          }
        })
      }),
    )
  }
  
  loadNextPage() {
    if (this.exhibitPage < this.maxPages) {
      this.exhibitPage = this.exhibitPage + 1;
      this.exhibitsService.loadMoreExhibits(this.categorySlug, this.exhibitPage)
    }
  }

}
