import { Component, OnInit } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { RegulationGQL} from 'src/generated/graphql';

@Component({
  selector: 'app-regulation',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.scss']
})
export class RegulationComponent implements OnInit {

  regulation$: Observable<any> = of();
  constructor(private regulationGql: RegulationGQL) { }

  ngOnInit(): void {
    this.regulation$ = this.regulationGql.watch().valueChanges.pipe(
      map(response => {
        return (response.data.regulation)
      })
    )
  }
}