import { Component, OnInit } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { PolicyGQL} from 'src/generated/graphql';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  policy$: Observable<any> = of();
  constructor(private policyGql: PolicyGQL) { }

  ngOnInit(): void {
    this.policy$ = this.policyGql.watch().valueChanges.pipe(
      map(response => {
        return (response.data.policy)
      })
    )
  }

}