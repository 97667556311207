import { Component, Input, OnInit } from '@angular/core';
import { Bid, Exhibit } from '@app/_models';
import { AccountService, BidsService } from '@app/_services';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, of } from 'rxjs';

@Component({
  selector: 'app-exhibit-bid',
  templateUrl: './exhibit-bid.component.html',
  styleUrls: ['./exhibit-bid.component.scss']
})
export class ExhibitBidComponent implements OnInit {

  @Input() exhibit!: Exhibit;
  account = this.accountService.accountValue;
  bid$: Observable<Bid> = of()

  constructor(
    private bidsService: BidsService,
    private accountService: AccountService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.bid$ = this.bidsService.forExhibit(this.exhibit.id)
  }

  refreshBids(): void {
    this.bidsService.reloadForExhibit(this.exhibit.id);
    this.toastr.success('Gebote aktualisiert', undefined, {
      timeOut: 1000
    });
  }
}
