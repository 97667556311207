import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers/auth.guard';
import { CategoryComponent } from './category/category.component';
import { ExhibitComponent } from './exhibit/exhibit.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HomeComponent } from './home/home.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { GtcStandaloneComponent } from './gtc-standalone/gtc-standalone.component';
import { PolicyStandaloneComponent } from './policy-standalone/policy-standalone.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';

const routes: Routes = [
    { path: 'agb', component: GtcStandaloneComponent },
    { path: 'datenschutz', component: PolicyStandaloneComponent },
    { path: 'register_success', component: RegisterSuccessComponent },
    {
        path: '', component: HomeComponent,
        children: [
            { path: '', component: CategoryComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'login', component: LoginComponent },
            { path: 'forgot', component: PasswordForgotComponent },
            { path: 'reset', component: PasswordResetComponent },
            { path: ':categorySlug', component: CategoryComponent },
            { path: ':categorySlug/:exhibitSlug', component: ExhibitComponent },
        ]
    },

    // otherwise redirect to categories
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', onSameUrlNavigation: 'reload', enableTracing: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }