import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Account } from '@app/_models/account';
import { ForgotPasswordGQL, LoginGQL, MeGQL, RegisterGQL, ResetPasswordGQL, UpdateMeGQL, UsersPermissionsLoginInput, UsersPermissionsLoginPayload, UsersPermissionsMe, UsersPermissionsRegisterInput } from 'src/generated/graphql';
import { ApolloError } from '@apollo/client/core';

@Injectable({ providedIn: 'root' })
export class AccountService {

  private accountSubject: BehaviorSubject<Account | null>;
  public account: Observable<Account | null>;


  constructor(
    private router: Router,
    private meGql: MeGQL,
    private updateMeGql: UpdateMeGQL,
    private loginGql: LoginGQL,
    private registerGql: RegisterGQL,
    private forgotPasswordGql: ForgotPasswordGQL,
    private resetPasswordGql: ResetPasswordGQL
  ) {
    this.accountSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('account')!));
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue() {
    return this.accountSubject.value;
  }

  login(loginInput: UsersPermissionsLoginInput) {
    return this.loginGql.mutate({ input: loginInput }).pipe(
      catchError((err: ApolloError) => {
        if (err.message.match(/not confirmed/i)) {
          throw new Error('Bitte bestätige zuerst deinen Account mit dem erhaltenen Email!')
        }
        if (err.message.match(/blocked/i)) {
          throw new Error('Dein Zugang wurde gesperrt!');
        }
        if (err.graphQLErrors[0]?.extensions['code'] == 'BAD_USER_INPUT') {
          throw new Error('Benutzername oder Passwort falsch');
        } else {
          throw new Error(err.message);
        }
      }),
      tap(response => {
        if (response && response.data) {
          this.loginAuthenticatedUser(response.data.login);
        }
      })
    )
  };

  register(registerInput: UsersPermissionsRegisterInput) {
    return this.registerGql.mutate({ input: registerInput }).pipe(
      catchError(err => {
        if (err.message.match(/already taken/i)) {
          throw new Error('Benutzername ist schon vergeben');
        } else {
          throw new Error(err.message);
        }
      })
    )
  }

  forgotPassword(email: string)  {
    return this.forgotPasswordGql.mutate({ email: email }).pipe(
      catchError(err => {
        throw new Error(err.message);
      })
    )
  }

  resetPassword(password: string, code: string) {
    return this.resetPasswordGql.mutate({ password: password, code: code }).pipe(
      catchError(err => {
        if (err.message.match(/incorrect code/i)) {
          throw new Error('Ungültiger Code zum Zurücksetzen verwendet');
        }
        throw new Error(err.message);
      }),
      tap(response => {
        if (response && response.data && response.data.resetPassword) {
          this.loginAuthenticatedUser(response.data.resetPassword);
        }
      })
    )
  }

  get(): Observable<Account> {
    return this.meGql.watch().valueChanges.pipe(
      catchError(err => {
        throw new Error(err.message)
      }),
      map(response => {
        if (!response || !response.data.me) {
          throw new Error("Invalid Account value")
        }

        return this.transformToAccount(response.data.me)
      })
    )
  };

  update(params: any): Observable<any> {
    return this.updateMeGql.mutate({ data: params }).pipe(
      tap(response => {
        if (response && response.data) {
          localStorage.setItem('account', JSON.stringify(response.data.updateMe))
          this.accountSubject.next(JSON.parse(localStorage.getItem('account')!))
        }
      })
    )
  }

  logout() {
    localStorage.removeItem('account');
    localStorage.removeItem('jwt');
    this.accountSubject.next(null);

    this.router.navigate(['/']);
  }

  private loginAuthenticatedUser(response: UsersPermissionsLoginPayload): void {
    const jwt = response.jwt;
    if (!jwt) {
      throw new Error("No JWT provided for login");
    }

    localStorage.setItem('account', JSON.stringify(response.user));
    localStorage.setItem('jwt', jwt);
    this.accountSubject.next(JSON.parse(localStorage.getItem('account')!))
  }
  
  private transformToAccount(accountData: UsersPermissionsMe): Account {
    if (
      !accountData.id ||
      !accountData.firstName ||
      !accountData.lastName ||
      !accountData.address ||
      !accountData.zip ||
      !accountData.city ||
      !accountData.email ||
      !accountData.mobile
      ) {
      throw new Error(`Missing Account attributes: ${accountData}`);
    }

    return {
      id: accountData.id,
      firstName: accountData.firstName,
      lastName: accountData.lastName,
      address: accountData.address,
      zip: accountData.zip,
      city: accountData.city,
      username: accountData.username,
      email: accountData.email,
      mobile: accountData.mobile
    }
  }
}