<div *ngIf="exhibit$ | async as exhibit" id="exhibit-{{exhibit.slug}}" class="bottom-border">
  <ul class="menu">
    <li class="no_border">
      <h1>
        <a [routerLink]="['/', exhibit.category.slug]" [fragment]="exhibit.slug">&#65308; ZURÜCK</a>
      </h1>
    </li>
  </ul>
  <div [id]="exhibit.slug" class="exhibit">
    <img src="{{ exhibit.image.formats.large.url || exhibit.image.url }}" class="img_large">
    <h3>{{ exhibit.title }}</h3>
    <div class="exhibit_content_large_nop">
      <a href="{{ exhibit.image.url}}" target="_blank">BILD GRÖSSER ANZEIGEN</a>
      <p [innerHtml]="exhibit.text"></p>
      <p [innerHtml]="exhibit.moretext"></p>
      <ng-container *ngIf="exhibit.sold;else for_sale">
        <p>Verkauft!</p>
      </ng-container>
      <ng-template #for_sale>
        <ng-container *ngIf="exhibit.minimum_amount;else noprice">
          <p>CHF {{exhibit.minimum_amount}}.— exkl. MWST.</p>
        </ng-container>
        <ng-template #noprice>
          <p>Preis auf Anfrage.</p>
        </ng-template>
      </ng-template>
    </div>
    <ng-container *ngIf="exhibit.auctionEnabled">
      <app-exhibit-bid id="#exhibit-bid" [exhibit]="exhibit"></app-exhibit-bid>
    </ng-container>
  </div>

  <ng-container *ngIf="exhibit.auctionEnabled">
    <ng-container *ngIf="account;else notloggedin">
      <app-bids-form *ngIf="account" [exhibit]="exhibit"></app-bids-form>
    </ng-container>
    <ng-template #notloggedin>
      <div class="account_item">
        <button routerLink="/login" [queryParams]="{returnUrl:currentUrl}">
          <h2>ANMELDEN</h2>
        </button>
      </div>
    </ng-template>
  </ng-container>
</div>