import { ViewportScroller } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Bid, Exhibit } from '@app/_models';
import { AccountService, BidsService, TanService } from '@app/_services';
import { ToastrService } from 'ngx-toastr';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bids-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class BidsFormComponent implements OnInit, OnDestroy {

  destroy$ = new Subject<void>();

  @Input() exhibit!: Exhibit;


  form!: FormGroup;
  minimumBidAmount?: number | null;
  tanValidationEnabled: boolean = true;
  hasToken = false;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private bidsService: BidsService,
    private tanService: TanService,
    private toastr: ToastrService,
    private viewportScroller: ViewportScroller
  ) { }

  get f() { return this.form.controls; }

  ngOnInit(): void {
    this.bidsService.forExhibit(this.exhibit.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe((bid: Bid | undefined) => {
      this.minimumBidAmount = bid ? (bid.amount + this.exhibit.bidding_step) : this.exhibit.minimum_amount;
      if (this.form?.controls?.['amount']) {
        this.form.controls['amount'].setValue(this.minimumBidAmount);
      }
    })

    this.tanService.getEnabled().pipe(
      takeUntil(this.destroy$)
    ).subscribe(enabled => {
      this.tanValidationEnabled = enabled

      const formFields = {
        amount: [this.minimumBidAmount, [Validators.required, Validators.pattern("^[-]?[0-9]*$")]]
      }
      this.form = this.formBuilder.group(
        this.tanValidationEnabled ? {
          ...formFields,
          tan: ['', Validators.required]
        } : formFields
      )
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFetchToken(): void {
    this.tanService.create()
    .pipe(first())
    .subscribe({
      next: (response: string | void) => {
        this.hasToken = true;
        if (response) {
          this.toastr.info(response);
          this.hasToken = true;
        }
        this.bidsService.reloadForExhibit(this.exhibit.id);
      },
      error: (_error) => {
        this.toastr.error('Etwas ist schiefgegangen. Bitte Seite neu laden.');
      }
    })
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const bidInput = {
      amount: this.form.controls['amount'].value,
      exhibit: this.exhibit.id,
      users_permissions_user: this.accountService.accountValue?.id
    }

    this.bidsService.create(
      this.tanValidationEnabled ? {
        ...bidInput,
        otp: this.form.controls['tan'].value
      } : bidInput,
    ).pipe(first()).subscribe({
        next: (data) => {
          this.hasToken = false;
          this.submitted = false;
          this.form.reset();
          this.form.controls['amount'].setValue(bidInput.amount + this.exhibit.bidding_step);
          this.toastr.success('Gebot erstellt!')
          this.viewportScroller.scrollToAnchor('#exhibit-bid');
        },
        error: error => {
          if (error.message.match(/beendet/i)) {
            this.toastr.error(error.message);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
          this.bidsService.reloadForExhibit(this.exhibit.id);
          this.toastr.error(error.message);
          this.submitted = false;
        }
      })
  }

  showForm(): boolean {
    return !this.tanValidationEnabled || this.hasToken
  }
}
